import { stringfy, version } from '../utils/index';

const CFG = window.CFG;
const HUNTER_CONFIG = window.HUNTER_CONFIG;

const init = function(options) {
  const proxied = window.XMLHttpRequest.prototype.open;

  let doJoinStart;

  window.XMLHttpRequest.prototype.open = function() {
    const url = arguments[1] ? arguments[1].split('?')[0] : undefined;

    if (url) {
      this.addEventListener('readystatechange', function() {
        if (this.readyState === 1 && url.match(/token\/getToken/g)) {
          doJoinStart = new Date() * 1
        }
    
        if (this.readyState === 4 && this.status === 200 && url.match(/(activity|pluginTools)\/result/g)) {
          try {
            if (JSON.parse(this.response).data.orderId) {
              window.HUNTER_CONFIG.a_oId = 'taw-' + JSON.parse(this.response).data.orderId;
            }
          } catch(err) {}

          // 不上报
          if (doJoinStart) {
            const time = new Date() * 1 - doJoinStart;

            const opts = {
              client: HUNTER_CONFIG.client,
              version,
              appId: CFG.appId || '',
              pageId: HUNTER_CONFIG.pageId,
              time
            };
            (new Image()).src = `${options.url}?${stringfy(opts)}`;
          }
        }
      })
    }
    
    return proxied.apply(this, [].slice.call(arguments));
  }
}

export default { init };

