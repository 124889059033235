/*
 * @Author: 周成
 * @Date: 2019-02-18 15:34:51
 * @Last Modified by: 周成
 * @Last Modified time: 2019-04-29 11:31:20
 */
import { getPageId } from './utils';

import pref from './pref'; // 页面性能监控

import ajax from './ajax'; // ajax性能监控

import doJoin from './doJoin'; // 出券时间统计

import doJoinAll from './doJoinAll'; // 出券整体时间统计

import customized from './customized'; // 自定义字段统计

var domain = 'https://hunter-report.dui88.com';
// var domain = 'http://localhost:3000';
window.HUNTER_CONFIG = window.HUNTER_CONFIG || {};
const CFG = window.CFG || {};
window.HUNTER_CONFIG.pageId = HUNTER_CONFIG.pageId || CFG.skinName || CFG.id || getPageId();

pref.init({
  url: `${domain}/report`
});

ajax.init({
  url: `${domain}/report/ajax`
});

doJoin.init({
  url: `${domain}/report/doJoin`
})

doJoinAll.init({
  url: `${domain}/report/doJoinAll`
})

customized.init({
  url: `${domain}/report/customized`
})