import { stringfy, version, getCookie } from '../utils/index';

const CFG = window.CFG;
const HUNTER_CONFIG = window.HUNTER_CONFIG;

const init = function(options) {
  const reporter = function() {
    return this;
  }
  reporter.prototype.start = function() {
    this.startTime = new Date() * 1;
  },
  reporter.prototype.end = function() {
    if (!this.startTime) return;

    const opts = {
      client: HUNTER_CONFIG.client, 
      version,
      appId: CFG.appId || '',
      pageId: HUNTER_CONFIG.pageId,
      time: new Date() * 1 - this.startTime,
      a_oId: getCookie('_coll_oId') || HUNTER_CONFIG.a_oId || '', // 订单ID
      activityId: CFG.activityId || '', // consumerId
      slotId: CFG.slotId || '', // 广告位ID
      consumerId: CFG.consumerId || '' // consumerId
    };
    (new Image()).src = `${options.url}?${stringfy(opts)}`
  }
  window.reportDoJoinAll = new reporter(options);
}

export default { init };

