/*
 * @Author: hyf
 * @Date: 2018-06-12 16:32:49
 * @Last Modified by: 周成
 * @Last Modified time: 2019-03-08 17:50:25
 */

const version = require('../../package.json').version;

function stringfy(obj) {
  if (!obj || typeof obj !== 'object') {
    return;
  }
  const encode = encodeURIComponent;
  const arr = Object.keys(obj).map(item => item + '=' + encode(obj[item]));
  return arr.join('&');
}

function extend(src) {
  let arg = arguments;
  if (arg.length >= 2) {
    for (let i = 1, len = arg.length; i < len; i++) {
      for (let key in arg[i]) {
        src[key] = arg[i][key];
      }
    }
  }
  return src;
}

function filterParam(obj) {
  let arg = arguments;
  if (arg.length >= 2) {
    for (let i = 1, len = arg.length; i < len; i++) {
      delete obj[arg[i]];
    }
  }
}

function getCookie(objName){//获取指定名称的cookie的值 
  var arrStr = document.cookie.split("; "); 
  for (var i = 0; i < arrStr.length; i++) { 
      var temp = arrStr[i].split("="); 
      if (temp[0] == objName){ 
          return decodeURI(temp[1]); 
      }
  } 
}

/**
 * 获取页面URL除去参数，作为pageId
 */
function getPageId(){
  let relUrl = document.URL.split('//')[1];
  if (relUrl.indexOf('?') !== -1) {
    relUrl = relUrl.split('?')[0];
  }
  return relUrl;
}

export { version, stringfy, extend, filterParam, getCookie, getPageId };
