/*
 * @Author: hyf
 * @Date: 2018-06-12 16:32:40
 * @Last Modified by: 周成
 * @Last Modified time: 2019-02-18 16:56:00
 */
import { stringfy, extend, version, getCookie } from '../utils/index';

import prefParmas from './perf';
import otherParmas from './other';

function init(options) {
  const baseParmas = {
    client: HUNTER_CONFIG.client,
    appId: CFG.appId || '', // 媒体ID
    pageId: HUNTER_CONFIG.pageId, // 页面ID
    slotId: CFG.slotId || '', // 广告位ID
    consumerId: CFG.consumerId || '', // consumerId
    activityId: CFG.activityId || '', // consumerId
    version,
    url: location.href,
    a_oId: getCookie('_coll_oId') || '', // 订单ID
    rid: getCookie('_coll_rid') || '', // 广告位全链路追踪ID
  };

  if (prefParmas) {
    let parmas = extend(baseParmas, prefParmas, otherParmas);

    // 自定义 白屏、首屏、整屏
    if (document.getElementById('vm-preload')) {
      parmas.fmp = parmas.ready;
    } else {
      parmas.fmp = parmas.load;
    }

    function send(definedParams) {
      parmas = extend(parmas, definedParams);
      new Image().src = `${options.url}?${stringfy(parmas)}`;
    }
    send({});
  }
}

export default { init };
