import { stringfy, version } from '../utils/index';

const CFG = window.CFG;
const HUNTER_CONFIG = window.HUNTER_CONFIG;

const init = function(options) {
  window.reportCustomized = function(params) {
    if (!params.field) return;
    const opts = {
      client: HUNTER_CONFIG.client,
      version,
      appId: CFG.appId || '',
      pageId: HUNTER_CONFIG.pageId,
      ...params
    };
    (new Image()).src = `${options.url}?${stringfy(opts)}`
  }
}

export default { init };

