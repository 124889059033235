/*
 * @Author: hyf 
 * @Date: 2018-06-12 16:32:36 
 * @Last Modified by: 周成
 * @Last Modified time: 2019-02-27 14:33:59
 */

// 获取硬件信息

const other = (function() {

  const network = navigator.connection ? navigator.connection.effectiveType : '';

  const screen = window.screen || {};
  
  return {
    network, // 网络
    width: screen.width, // 屏幕宽度
    height: screen.height // 屏幕高度
  }
})();
  
export default other;