import { stringfy, version } from '../utils';

const { client } = window.HUNTER_CONFIG;

const init = function(options) {
  const proxied = window.XMLHttpRequest.prototype.open;
  window.XMLHttpRequest.prototype.open = function() {
    try {
      const method = arguments[0];
      const url = arguments[1] ? arguments[1].split('?')[0] : undefined;

      // 忽略掉异常接口和跨域接口
      if (url && !url.match(/\/\/(.+\.)?.+\..+/)) {
        let start;
        this.addEventListener('readystatechange', function() {
          if (this.readyState === 1) {
            start = (new Date()).getTime();
          }
          if (this.readyState === 4) {
            const status = this.status;
            const rt = (new Date()).getTime() - start;
            const rs = this.response ? this.response.length : 0;
  
            const params = {
              client,
              version,
              url,
              status,
              method,
              rt,
              rs
            }
            // 取消发送满请求，只记录错误请求
            // if ((status !== 200 && status !== 0) || (status === 200 && rt > 3000)) {
            if (status !== 200 && status !== 0) {
              (new Image()).src = `${options.url}?${stringfy(params)}`;
            }
          }
        })
      }
    } catch (error) {
      
    }
    return proxied.apply(this, [].slice.call(arguments))
  }
}

export default { init };